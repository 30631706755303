import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`*`}{`Update as of 3:30pm: Still no water at East but it’s supposed to
be on today so we won’t cancel the 5:30 class.  If you need running
water head to the Ville for the 4:30 or 5:30 class.   Main Street is
closed at Clay because of the sewer break so you may want to come in
down River Road or Washington Street.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Attention Louisville East 6:00am & 10:00am class attendees!  These
classes will be cancelled tomorrow because there is no water at East.
 The problem should be fixed by tomorrow’s 5:30pm class.  Please attend
The Ville’s 6:00am or 12:00pm class tomorrow instead.  Thanks.`}</strong></p>
    <p><em parentName="p">{`Last Day of Test Week!`}</em></p>
    <p>{`Shoulder Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Pendlay Row 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`10-Bench Press (155/95)`}</p>
    <p>{`15-Ring Rows`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will have our free Labor Day workout at 10:30 at CrossFit The
Ville this coming Monday so invite a friend!  All other classes and open
gym is cancelled for the day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      